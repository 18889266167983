import Swiper from 'swiper/swiper-bundle';

document.addEventListener('DOMContentLoaded', () => {
  const swiperElements = document.querySelectorAll('[id^=swiper-elements]');
  if (!swiperElements.length) return;

  const swiperOptions = {
    breakpoints: {
      450: { spaceBetween: 30 },
      550: { slidesPerView: 1.5 },
      680: { slidesPerView: 2 },
    },
    centeredSlides: true,
    navigation: {
      disabledClass: 'p-swiper__btn--disabled',
      nextEl: '.p-swiper__btn--next',
      prevEl: '.p-swiper__btn--prev',
    },
    pagination: {
      bulletClass: 'p-swiper__bullet',
      bulletActiveClass: 'p-swiper__bullet--current',
      clickable: true,
      el: '.p-swiper__pagination',
    },
    setWrapperSize: true,
    slideClass: 'p-swiper__slide',
    slidesPerView: 1.3,
    spaceBetween: 20,
    wrapperClass: 'p-swiper__slider',
  };

  function toggleSwiper(elements, options) {
    const windowWidth = window.innerWidth;
    const responsiveBreakpointWidth = 769; // 769px: 768pxまでがスマホとiPad縦画面

    elements.forEach((element) => {
      // responsiveBreakpointWidth未満ならインスタンス生成、以上ならインスタンスを破棄
      if (windowWidth < responsiveBreakpointWidth) {
        if (!element.swiper) new Swiper(element, options);
      } else {
        if (element.swiper) element.swiper.destroy();
      }
    });
  }

  // 初回実行
  toggleSwiper(swiperElements, swiperOptions);
  // windowリサイズ時に実行
  window.addEventListener('resize', () => {
    toggleSwiper(swiperElements, swiperOptions);
  });
});
