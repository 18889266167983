document.addEventListener('DOMContentLoaded', () => {
  const parentElement = document.getElementById('remove_tie_modal');
  if (!parentElement) return;

  const cancelButton = document.getElementById('cancel');
  cancelButton.addEventListener('click', () => {
    parentElement.style.display = 'none';
  });

  const openButton = document.getElementById('open');
  openButton.addEventListener('click', () => {
    parentElement.style.display = 'block';
  });
});
