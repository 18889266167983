document.addEventListener('DOMContentLoaded', () => {
  const favoriteButton = document.getElementById('video_favorite_btn');
  if (favoriteButton != null) {
    favoriteButton.addEventListener('change', favorite);
  }

  function favorite() {
    const id = favoriteButton.dataset.id;
    const token = favoriteButton.dataset.token;
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
    if (favoriteButton.checked) {
      fetch('/internal_api/favorites/', {
        method: 'post',
        headers: headers,
        body: JSON.stringify({ id: id }),
      });
    } else {
      fetch(`/internal_api/favorites/${id}`, {
        method: 'delete',
        headers: headers,
      });
    }
  }
});
