/************************************************************
 * 医療資格チェックモーダル
 ************************************************************/

import * as Cookies from 'js-cookie';

const modal = document.getElementById('modal_certification_check');
const isDoctorBtn = document.getElementById('is_doctor');

if (!Cookies.get('is_doctor')) {
  modal.classList.remove('hide');
  modal.classList.add('show');
}
if (isDoctorBtn !== null) {
  // 問いに対し「はい」を選択した場合、31日間モーダルを非表示に
  isDoctorBtn.addEventListener('click', function () {
    modal.classList.remove('show');
    modal.classList.add('hide');
    Cookies.set('is_doctor', true, { expires: 31 });
  });
}
