import Swiper from 'swiper/swiper-bundle';

document.addEventListener('DOMContentLoaded', () => {
  const modalOpenButtons = document.querySelectorAll(
    'input.myModal-slide_open'
  );
  if (!modalOpenButtons.length) return;

  const mainContainer = document.querySelector(
    'div.gallery-swiper-container.gallery-top'
  );
  if (!mainContainer) return;
  const thumbsContainer = document.querySelector(
    'div.gallery-swiper-container.gallery-thumbs'
  ); // optional

  initSwiper(mainContainer, thumbsContainer);

  modalOpenButtons.forEach((button) => {
    button.addEventListener('click', () => {
      if (!mainContainer.swiper) return;
      // open specific image correspond to the button by modal slide
      const index = [...modalOpenButtons].indexOf(button);
      mainContainer.swiper.slideTo(index);
      // update modal position/size after windows resized
      mainContainer.swiper.update();
      // update thumbsContainer after mainContainer modified
      if (thumbsContainer && thumbsContainer.swiper)
        thumbsContainer.swiper.update();
    });
  });

  function initSwiper(mainContainer, thumbsContainer) {
    if (thumbsContainer && !thumbsContainer.swiper) {
      new Swiper(thumbsContainer, {
        allowTouchMove: false,
        slidesPerView: 4,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
      });
    }
    const thumbsSwiper = thumbsContainer ? thumbsContainer.swiper : null;
    if (!mainContainer.swiper) {
      new Swiper(mainContainer, {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        thumbs: { swiper: thumbsSwiper },
      });
    }
  }
});
