document.addEventListener('DOMContentLoaded', () => {
  // 各elementsのpaddingを含んだ高さを配列で返す
  function getClientHeights(elements) {
    if (!elements || elements.length == 0) return [];
    // .clientHeight(): paddingを含んだ高さ, .height(): 高さ
    return [...elements].map((it) => it.clientHeight);
  }
  // 各elements内の最大のpaddingを含んだ高さを返す
  function getMaxClientHeight(elements) {
    const heights = getClientHeights(elements);
    if (!heights || heights.length == 0) return;
    return Math.max(...heights);
  }
  // 各elementsに指定した高さを設定する
  function setHeight(elements, height) {
    if (!elements || elements.length == 0 || !height) return;
    [...elements].map((it) => (it.style.height = `${height}px`));
  }

  const updataDetails = document.querySelectorAll(
    '.pharmaceutical__updata-detail'
  );
  setHeight(updataDetails, getMaxClientHeight(updataDetails));

  const searchAreas = document.querySelectorAll('.search-area');
  setHeight(searchAreas, getMaxClientHeight(searchAreas));

  const machH3s = document.querySelectorAll('.mach-h3');
  setHeight(machH3s, getMaxClientHeight(machH3s));

  const materialTitles = document.querySelectorAll('.p-prod-material__title');
  setHeight(materialTitles, getMaxClientHeight(materialTitles));

  const materialDescriptions = document.querySelectorAll(
    '.p-prod-material__description'
  );
  setHeight(materialDescriptions, getMaxClientHeight(materialDescriptions));
});
