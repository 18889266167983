window.addEventListener('DOMContentLoaded', function () {
  const seriesTitle = document.getElementById('series_title');
  let scrollCount = 0;

  if (seriesTitle !== null) {
    seriesTitle.addEventListener('mouseenter', textScroll);
  }

  function textScroll() {
    // 1pxずつ要素を左にスクロール
    seriesTitle.scrollLeft = ++scrollCount;

    // 左へのスクロール量がテキストの長さを超えたら、カウントとテキストスクロールをリセット
    if (scrollCount > seriesTitle.scrollWidth - seriesTitle.clientWidth) {
      scrollCount = seriesTitle.scrollLeft = 0;
    }

    const timer = setTimeout(textScroll, 20);

    // カーソルが要素外に出たらスクロールストップ＆テキストスクロールをリセット
    seriesTitle.addEventListener('mouseleave', function () {
      clearTimeout(timer);
      scrollCount = seriesTitle.scrollLeft = 0;
    });
  }
});
