import Swiper from 'swiper/swiper-bundle';

document.addEventListener('DOMContentLoaded', () => {
  const wrapper = document.querySelector('section.article__top div.mainSlides');
  if (!wrapper) return;

  const swiperParams = {
    autoplay: {
      delay: 4000, // 4秒後にスライド
      disableOnInteraction: false, // autoplay will not be disabled after user interactions (swipes)
      pauseOnMouseEnter: true, // When enabled autoplay will be paused on pointer (mouse) enter over Swiper container
    },
    breakpoints: {
      // when window width is >= 2048px
      2048: {
        slidesPerView: 4,
      },
      // when window width is >= 1501px
      1501: {
        slidesPerView: 3,
      },
      // when window width is >= 982px
      982: {
        slidesPerView: 2,
      },
    },
    // Set to true to enable continuous loop mode
    // Because of nature of how the loop mode works (it will rearrange slides),
    // total number of slides must be >= slidesPerView * 2
    loop: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      clickable: true,
      el: '.swiper-pagination',
    },
    slidesPerView: 1,
    mousewheel: {
      forceToAxis: true,
    },
  };

  new Swiper(wrapper, swiperParams);
});
