window.addEventListener('DOMContentLoaded', function () {
  const modal = document.getElementById('modal_product_bbs_form');
  const displayBtns = document.querySelectorAll(
    '[id^=display_production_bbs_form]'
  );
  const hideBtn = document.getElementById('hide_production_bbs_form');
  const hideClass = 'p-prod-bbs-form--hide';
  const displayClass = 'p-prod-bbs-form--display';

  // モーダル・表示ボタン・非表示ボタンのどれかが存在しない場合、何もせずリターン
  if (!modal || !displayBtns.length || !hideBtn) {
    return;
  }

  [].forEach.call(displayBtns, function (displayBtn) {
    displayBtn.addEventListener('click', function () {
      modal.classList.remove(hideClass);
      modal.classList.add(displayClass);
    });

    hideBtn.addEventListener('click', function () {
      modal.classList.remove(displayClass);
      modal.classList.add(hideClass);
    });
  });
});
