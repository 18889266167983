const containerSelector = '.thumb-wrapper-container';
const wrapperSelector = '.thumb-wrapper';

const setupThumbWrapper = (node) => {
  const fileForms = node.querySelectorAll('input[type=file]');
  const thumb = node.querySelector('img.thumb');

  fileForms.forEach((fileForm) => {
    fileForm.addEventListener('change', (e) => {
      const image = e.target.files[0];
      const reader = new FileReader();

      reader.onload = () => {
        thumb.src = reader.result;
      };

      reader.readAsDataURL(image);
    });
  });
};

document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll(wrapperSelector).forEach((node) => {
    setupThumbWrapper(node);
  });

  document.querySelectorAll(containerSelector).forEach((node) => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        mutation.addedNodes.forEach((addedNode) => {
          addedNode.querySelectorAll(wrapperSelector).forEach((node) => {
            setupThumbWrapper(node);
          });
        });
      });
    });

    observer.observe(node, { childList: true });
  });
});
