import Swiper from 'swiper/swiper-bundle';

document.addEventListener('DOMContentLoaded', () => {
  const wrappers = document.querySelectorAll('div.js_swiper');
  wrappers.forEach((it) => toggleSwiper(it));

  window.addEventListener('resize', () => {
    wrappers.forEach((it) => toggleSwiper(it));
  });

  function toggleSwiper(element) {
    const clientWidth = document.documentElement.clientWidth;
    const breakpointWidth = 982;

    if (clientWidth >= breakpointWidth) {
      applySwiper(element);
    } else {
      if (element.swiper) element.swiper.destroy(); // instanceがあれば破棄
    }
  }

  function applySwiper(element) {
    if (element.swiper) return; // 既にinstanceがあればskip

    const swiperParams = {
      autoHeight: true,
      lazy: false,
      breakpoints: {
        // when window width is >= 2560px
        2560: {
          slidesPerView: 6.3,
        },
        // when window width is >= 982px
        982: {
          slidesPerView: 4.2,
        },
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      slidesPerView: 1.6, // default
      mousewheel: {
        forceToAxis: true,
      },
    };
    element.swiper = new Swiper(element, swiperParams);
  }
});
