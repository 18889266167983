import Player from '@vimeo/player';

document.addEventListener('DOMContentLoaded', () => {
  // idがplayer_で始まるiframe (_vimeo_iframe.html.erb参照)
  const iframe = document.querySelector('*|iframe[id^=player_]');
  if (iframe == null) return;
  if (!/vimeo/.test(iframe.src)) return;
  const player = new Player(iframe);
  const token = iframe.dataset.token;
  const params = {
    id: null,
    user_id: iframe.dataset.user_id,
    content_id: iframe.dataset.content_id,
    duration: null,
    current_time: null,
    ended: false,
  };
  // タイマーインターバル (milliseconds)
  const seconds = 1000; // 秒 = 1000ミリ秒
  const maxInterval = 60 * seconds; // 最大60秒
  const incrementalInterval = 10 * seconds; // 増分10秒
  let interval = 10 * seconds; // 初期10秒
  // タイマー識別子
  let timerId = null;

  function setTimer() {
    timerId = setInterval(async () => {
      console.info(`timerId: ${timerId}, ${interval / seconds}s have passed.`);
      await refreshAndPost();
    }, interval);
    console.info(
      `setTimer() timerId: ${timerId}, interval: ${interval / seconds}s`
    );
  }

  function clearTimer() {
    if (!timerId) return;
    clearInterval(timerId);
    console.info(`clearTimer(${timerId})`);
    timerId = null;
  }

  // 再生を開始した時
  player.on('play', async () => {
    console.info('play!');
    if (!params.duration) params.duration = 0;
    if (!params.current_time) params.current_time = 0;
    await refreshAndPost(); // 再生開始時点でPOST
    // 既に稼働しているタイマーがあったら解除する
    clearTimer();
    setTimer();
  });

  // 再生を停止した時
  player.on('pause', async () => {
    console.info('pause!');
    // タイマーキャンセル
    clearTimer();
    await refreshAndPost();
  });

  window.addEventListener('beforeunload', () => {
    // ページ離脱時 再生中なら停止させる ※iOSでは動作しない
    player.pause();
  });

  async function setPlayedData() {
    // played = array values of the played video time ranges.
    const played = await player.getPlayed();
    if (played == null) return;
    if (played.length == 0) return;
    const durations = played.map((range) => {
      // 再生時間 = 停止位置 - 開始位置
      const duration = range[1] - range[0];
      return duration;
    });
    params.duration = durations.reduce((sum, duration) => {
      return sum + duration;
    }, 0);
    params.current_time = Math.max(...flatten(played));
    // ended: 最後まで再生したか？ player.getEnded()だと一周後の再生でfalseになるので手動で判定
    // playedの値は有効桁数が多くvideoのdurationと完全一致しないので切上げて比較する
    const videoDuration = await player.getDuration();
    params.ended = Math.ceil(params.current_time) >= videoDuration;
  }

  function flatten(array) {
    return [].concat(...array);
  }

  async function refreshAndPost() {
    await setPlayedData();
    await post();
  }

  // 視聴ログ記録api呼出し
  async function post() {
    if (params.duration == null || params.current_time == null) return;
    if (params.duration < 0) return;
    const url = '/internal_api/watch_histories/';
    const sanitized = sanitizeParams(params);
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
    const init = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(sanitized),
    };
    const response = await fetch(url, init);
    const json = await response.json();
    const resultId = json.id;
    console.info(`result id: ${resultId}, duration: ${params.duration}s`);
    if (resultId) {
      params.id = resultId;
    } else if (timerId) {
      // 失敗時に稼働中タイマーのinterval増加
      interval = Math.min(interval + incrementalInterval, maxInterval);
      clearTimer();
      setTimer();
    }
  }

  function sanitizeParams(object) {
    // accumulator {} に key: sanitize(value) を セット
    return Object.entries(object).reduce(
      (acc, [key, value]) => ({ ...acc, [key]: sanitize(value) }),
      {}
    );
  }

  // rails側でstringの'null'にならないようにjavascriptのnull(undefined)を空値にする
  function sanitize(value) {
    return value == null ? '' : value;
  }
});
