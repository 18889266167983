import { csrfToken } from '@rails/ujs';

document.addEventListener('DOMContentLoaded', () => {
  // 外部Link Click履歴取得
  // 例外的に内部コントローラを経由するdiwebを追加
  const hyperLinks = document.querySelectorAll(
    "a[href^='http']:not([href*='clinicalcloud.jp']), a[href^='/diweb'], a[href^='/medicines/download'], a[href^='/medical_equipment/download']"
  );
  hyperLinks.forEach((a) => {
    a.addEventListener('click', {
      handleEvent: post,
      url: a.href,
      organizationId: a.dataset.organizationId,
      productAttachmentId: a.dataset.productAttachmentId,
      equipmentAttachmentId: a.dataset.equipmentAttachmentId,
    });
  });

  async function post() {
    const sourcePath = location.pathname;
    const destinationURL = this.url;
    const apiURL = '/internal_api/click_logs/';
    const headers = {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken(),
    };
    const clickLog = {
      src_path: sourcePath,
      dst_url: destinationURL,
    };
    if (this.organizationId) clickLog['organization_id'] = this.organizationId;
    if (this.productAttachmentId) {
      clickLog['medicine_attachment_log_attributes'] = {
        product_attachment_id: this.productAttachmentId,
      };
    }
    if (this.equipmentAttachmentId) {
      clickLog['equipment_attachment_log_attributes'] = {
        equipment_attachment_id: this.equipmentAttachmentId,
      };
    }
    const params = {
      click_log: clickLog,
    };
    const init = {
      method: 'POST',
      credentials: 'same-origin',
      headers: headers,
      body: JSON.stringify(params),
    };
    const response = await fetch(apiURL, init);
    const json = await response.json();
    console.info(`response: ${JSON.stringify(json)}`);
  }
});
