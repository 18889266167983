window.addEventListener('DOMContentLoaded', () => {
  // aタグ要素の参照を取得
  const links = document.querySelectorAll('a[href^="#"]');

  // ページ内リンクが存在しないならリターン
  if (links === null) {
    return;
  }

  // 各aタグにクリックイベントを設定
  links.forEach((link) => {
    link.addEventListener('click', (e) => {
      // デフォルトのイベントをキャンセル
      e.preventDefault();

      // ページ内遷移先の要素を取得
      const elementId = link.href.split('#')[1];

      const element =
        elementId === ''
          ? document.getElementsByTagName('html')[0]
          : document.getElementById(elementId);

      // 対象のY軸の絶対座標を取得
      const elemY = element.getBoundingClientRect().top;
      // 現在のスクロール量を取得
      const scrollY = window.pageYOffset;
      // 対象までのスクロール量を算出
      const top = elemY + scrollY;

      window.scroll({
        top: top, // スクロール量の設定
        behavior: 'smooth', // スクロール動作の設定
      });
    });
  });
});
