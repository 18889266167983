/************************************************************
 * 登録情報更新促進モーダル
 ************************************************************/

import * as Cookies from 'js-cookie';

document.addEventListener('DOMContentLoaded', () => {
  const cookieName = 'updated_user_information';
  if (Cookies.get(cookieName)) return;
  const modal = document.getElementById('modal_update_user_information');
  if (!modal) return;

  const expireDays = 90;
  const modalShowClass = 'p-update-user-info-modal';
  const modalHideClass = 'p-update-user-info-modal--hide';
  const body = document.getElementsByTagName('body')[0];
  const hideModalBtns = [
    document.getElementById('updated_user_information'),
    document.getElementById('update_user_profiles'),
    document.getElementById('update_user_email'),
  ];

  // モーダルを表示
  body.style.position = 'fixed';
  modal.classList.remove(modalHideClass);
  modal.classList.add(modalShowClass);

  // 登録情報変更ページへ遷移した場合、または「登録情報に変更はありません」を選択した場合、モーダルを3ヶ月間非表示にする
  hideModalBtns.forEach((hideModalBtn) => {
    hideModalBtn.addEventListener('click', () => {
      body.style.position = '';
      modal.classList.remove(modalShowClass);
      modal.classList.add(modalHideClass);
      Cookies.set(cookieName, true, { expires: expireDays });
    });
  });
});
