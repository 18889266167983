import Swiper from 'swiper/swiper-bundle';

document.addEventListener('DOMContentLoaded', () => {
  const kawarabanSwiperContainer = document.querySelector(
    'section.section--kawaraban > div.swiper-container'
  );
  if (!kawarabanSwiperContainer) return;

  const swiperParams = {
    autoHeight: true,
    lazy: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    preloadImages: false,
  };
  const kawarabanSwiper = new Swiper(kawarabanSwiperContainer, swiperParams);

  const thumbnails = kawarabanSwiperContainer.querySelectorAll('img');
  thumbnails.forEach((it) => {
    it.addEventListener('load', () => {
      kawarabanSwiper.updateAutoHeight();
    });
  });
});
